import { useEffect, useState } from "react";
import { TextField, Box, Paper, IconButton, InputAdornment, Typography } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Spaces, mainBox } from "../../../assets/Variables/SpaceVariables.js";
import * as TextVariables from "../../../assets/Variables/TextVariables.js";
import * as styles from "../Styles.js";
import BreadkCrumbComponent from "../../../components/BreadCrumbs/BreadkCrumbComponent.js";
import ContinueButton from "../../../components/Buttons/ContinueButton.js";
import ChangePasswordRequest from "../../../Endpoints/ChangePasswordRequest.js";
import NotificationModal from "../../../components/Notifications/NotificationModal.js";
import { useNavigate } from "react-router-dom";

function PasswordSettingsPage() {
  const navigate = useNavigate();
  const [errors, setErrors] = useState({ newPassword: "", confirmPassword: "" });
  const [values, setValues] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
    showPassword: { current: false, new: false, confirm: false },
  });
  const [showNotification, setShowNotification] = useState(false);
  const [modalProps, setModalProps] = useState({
    iconType: "",
    title: "",
    message: "",
    onAcceptLabel: undefined,
    onRefuseLabel: undefined,
    onAccept: undefined,
    onRefuse: undefined,
  });
  
  const validatePassword = (password) => {
    const lengthValid = password.length >= 8;
    const upperValid = /[A-Z]/.test(password);
    const numberValid = /\d/.test(password);
    const specialCharValid = /[^a-zA-Z0-9]/.test(password);
    
    if (!lengthValid) return "Password must be at least 8 characters.";
    if (!upperValid) return "Password must contain at least one uppercase letter.";
    if (!numberValid) return "Password must contain at least one number.";
    if (!specialCharValid) return "Password must contain at least one special character.";
    return "";
  };
  
  const handleChange = (prop) => (event) => {
    const { value } = event.target;
    setValues({ ...values, [prop]: value });
    
    if (prop === "newPassword") {
      setErrors({ ...errors, newPassword: validatePassword(value) });
    }
    if (prop === "confirmPassword") {
      setErrors({ ...errors, confirmPassword: value !== values.newPassword ? "Passwords do not match." : "" });
    }
  };

  const handleClickShowPassword = (field) => {
    setValues({
      ...values,
      showPassword: { ...values.showPassword, [field]: !values.showPassword[field] },
    });
  };

  const ChangePasswordMethod = async() => {
    try{
      const response = await ChangePasswordRequest({
        currentPassword: values.currentPassword,
        newPassword: values.newPassword
      });
      if (response.status === 200) {
        setModalProps({
          iconType: "success",
          title: "Updated password",
          message: "Your password has been successfully updated!",
          onAcceptLabel: "Continue",
          onAccept: () => handleClickToBackPage(),
        });
        setShowNotification(true);
      }
    }
    catch(error){
      if(error.response.status === 400 || error.response.status === 404 || error.response.status === 401){
        setModalProps({
          iconType: "warning",
          title: "Error updating password",
          message: error.response.data.message,
          onAcceptLabel: "Try again",
          onRefuseLabel: "Close",
          onAccept: () => handleOnAccept(),
          onRefuse: () => handleOnRefuse(),
        });
        setShowNotification(true);
      }
      else{
        setModalProps({
          iconType: "warning",
          title: "Error in request mal ",
          message: "Please try later",
          onAcceptLabel: "Try again",
          onAccept: () => {},
        });
        setShowNotification(true);
        console.log(error);
      }
    }
  }

  const handleOnAccept = (optionalParam) => {
    if (optionalParam) {
      handleClickToBackPage();
    } else {
    }
  };

  const handleOnRefuse = () => {};

  const handleClickToBackPage = () => {
    navigate("/settings");
  };

  const handleCloseNotification = () => {
    setShowNotification(false);
  };

  useEffect(() => {
    
  }, []);

  const isFormValid = !errors.newPassword && !errors.confirmPassword && values.newPassword && values.confirmPassword;

  return (
    <Box sx={mainBox}>
      <NotificationModal
        open={showNotification}
        onClose={handleCloseNotification}
        iconType={modalProps.iconType}
        title={modalProps.title}
        message={modalProps.message}
        onAcceptLabel={modalProps.onAcceptLabel}
        onRefuseLabel={modalProps.onRefuseLabel}
        onAccept={modalProps.onAccept}
        onRefuse={modalProps.onRefuse}
      />
      <Box sx={styles.accountPreferences}>
        <BreadkCrumbComponent backPageLink={"/settings"} clientNumber={"Your account preferences"} clientName={""} clientCountry={""} />
      </Box>
      <Paper elevation={2} sx={styles.paperPassword}>
        <Box sx={{ width: 32, height: 32, padding: Spaces.Size_5 }}>
          <span className="material-symbols-outlined" style={{ fontSize: "32px" }}>
            password
          </span>
        </Box>
        <Box sx={{ width: 400, padding: Spaces.Size_5 }}>
          <Typography sx={TextVariables.Body_3_bold}>Change your password</Typography>
          
          <TextField
            fullWidth
            label="Current password"
            type={values.showPassword.current ? "text" : "password"}
            variant="outlined"
            margin="normal"
            value={values.currentPassword}
            onChange={handleChange("currentPassword")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => handleClickShowPassword("current")}> 
                    {values.showPassword.current ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          
          <TextField
            fullWidth
            label="New password"
            type={values.showPassword.new ? "text" : "password"}
            variant="outlined"
            margin="normal"
            value={values.newPassword}
            onChange={handleChange("newPassword")}
            error={!!errors.newPassword}
            helperText={errors.newPassword}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => handleClickShowPassword("new")}> 
                    {values.showPassword.new ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          
          <TextField
            fullWidth
            label="Confirm password"
            type={values.showPassword.confirm ? "text" : "password"}
            variant="outlined"
            margin="normal"
            value={values.confirmPassword}
            onChange={handleChange("confirmPassword")}
            error={!!errors.confirmPassword}
            helperText={errors.confirmPassword}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => handleClickShowPassword("confirm")}> 
                    {values.showPassword.confirm ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />

          <Box sx={styles.changePasswordButtonContainer}>
            <ContinueButton HandleOnClick={() => ChangePasswordMethod()} disable={!isFormValid}>Continue</ContinueButton>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}

export default PasswordSettingsPage;