import React, { useState, useEffect } from "react";
import { Box, Button, Snackbar, Typography, Paper } from "@mui/material";
import copy from "clipboard-copy";
import * as styles from "../ReferralLinks/Styles.js";
import * as TextVariables from "../../assets/Variables/TextVariables.js";
import TableComponent from "../../components/Table/TableComponent.js";
import Colors from "../../assets/Variables/ColorsVariables.js";
import {useCookies} from "../../Tools/CookieChecker.js";
import ReferralLinkRequest from "../../Endpoints/ReferralLinkRequest.js";
import { RemoveDashboardData } from "../../Tools/LocalStorage.js";
import {mainBox} from "../../assets/Variables/SpaceVariables.js";
import DynamicEmailModal from "./DynamicEmailModal.js";
import ShareReferralLinkRequest from "../../Endpoints/ShareReferralLinkRequest.js";
import NotificationModal from "../../components/Notifications/NotificationModal.js";
import LoadingSpinner from "../../components/Loaders/SpinLoader.js";

const registerUrlBase = "https://cabinet.tvmarkets.com/en/register/1/live-account";

function TreesIndexPage(props) {
  const [copiedId, setCopiedId] = useState(null);
  const [sendingEmail, setSendingEmail] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [shareLinkModal, setShareLinkModal] = useState(false);
  const [shareLinkString, setShareLinkString] = useState("");
  const { user, type, token, id } = useCookies();
  const [rows, setRows] = useState([]);
  const [showNotification, setShowNotification] = useState(false);
  const [modalProps, setModalProps] = useState({
    iconType: "",
    title: "",
    message: "",
    onAcceptLabel: undefined,
    onRefuseLabel: undefined,
    onAccept: undefined,
    onRefuse: undefined,
  });
  const [loading, setLoading] = useState(false);

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };
  const handleCloseNotification = () => {
    setShowNotification(false);
  };

  const RequestReferralLinkData = async() => {
    setLoading(true);
    try{
        const response = await ReferralLinkRequest({enable: true});
        if(response.data != null){
            let storedTrees = response.data.data.trees;    
            if (storedTrees && Array.isArray(storedTrees) && storedTrees.length > 0) { 
              let actualReferral = storedTrees.map((referral, index) => {
                if(response.data.data.role === "IB"){
                  const regex = /\(([^)]+)\)/; 
                  const coincidence = referral.description.match(regex);
                  referral.description = coincidence ? coincidence[1] : referral.description
                }
                let row = {
                    id: referral.id,
                    tree_name: referral.description,
                    language: referral.language,
                    entity: referral.entity_initials,
                    link: referral.link,
                    prefix: referral.login_prefix,
                    trading_platform: referral.server
                }
                return row;
              });
              setRows(actualReferral);
            }
        }
      }catch (error){
        console.log(error);
        RemoveDashboardData();
    }
    setLoading(false);
  }

  useEffect(() => {
    RequestReferralLinkData();
  }, []);

  const RequestShareReferralLinkData = async(e) => {
    setShareLinkModal(false);
    setLoading(true);
    try{
      const response = await ShareReferralLinkRequest({
        link: shareLinkString,
        emailsToSend: e
      });
      if(response.status === 200){
        setModalProps({
          iconType: "success",
          title: "Link shared satisfactorily",
          message: response.data.data.message,
          onAcceptLabel: "Continue"
        });
        setShowNotification(true);
      }
      else{
        setModalProps({
          iconType: "error",
          title: "Link sharing error",
          message: response.data.data.message,
          onAcceptLabel: "Try again"
        });
        setShowNotification(true);
      }
    }
    catch (error){
      console.log(error);
      if(error.response.status === 400 || error.response.status === 403){
        setModalProps({
          iconType: "error",
          title: "Link sharing error",
          message: error.response.data.data.message,
          onAcceptLabel: "Try again"
        });
        setShowNotification(true);
      }
      else{
        setModalProps({
          iconType: "error",
          title: "Server error",
          message: "An internal error has occurred, please try again later",
          onAcceptLabel: "Try again"
        });
        setShowNotification(true);
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    RequestReferralLinkData();
  }, []);

  const handleDoubleRowClick = () => { };

  const columns = [
    {
      field: "tree_name",
      headerName: "Tree Name",
      headerClassName: "header_table",
      renderHeader: () => <>{"Tree Name"}</>,
    },
    {
      field: "language",
      headerName: "Language",
      headerClassName: "header_table",
      renderHeader: () => <>{"Language"}</>,
    },
    {
      field: "entity",
      headerName: "Entity",
      headerClassName: "header_table",
      renderHeader: () => <>{"Entity"}</>,
    },
    {
      field: "trading_platform",
      headerName: "Trading platform",
      headerClassName: "header_table",
      renderHeader: () => <>{"Trading platform"}</>,
    },
    {
      field: "link",
      headerName: "Share link",
      headerClassName: "header_table",
      renderHeader: () => <>{"Share link"}</>,
      sortable: false,
      filterable: false,
      hideable: false,
      resizable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const handleCopy = () => {
          if(process.env.REACT_APP_WEB_API_URL == "https://api-crm-portal.tvmarkets.com/api"){
            copy(`${registerUrlBase}?ib=${id}&ib_tree=${params.row.id}&lang=${params.row.language}&prefix=${params.row.prefix}`);
          }
          else{
            const domain = window.location.origin;
            copy(`${domain}/register?ib=${id}&ib_tree=${params.row.id}&lang=${params.row.language}&prefix=${params.row.prefix}`);
          }

          setTimeout(() => {
            setCopiedId(params.id);
          }, 0);
          setTimeout(() => {
            setCopiedId(null);
          }, 2000);
        };
        const handleShare = () => {
          if(process.env.REACT_APP_WEB_API_URL == "https://api-crm-portal.tvmarkets.com/api"){
            setShareLinkString(`${registerUrlBase}?ib=${id}&ib_tree=${params.row.id}&lang=${params.row.language}&prefix=${params.row.prefix}`);
          }
          else{
            const domain = window.location.origin;
            setShareLinkString(`${domain}/register?ib=${id}&ib_tree=${params.row.id}&lang=${params.row.language}&prefix=${params.row.prefix}`);
          }
          setShareLinkModal(true)
        };

        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              height:'100%',
              width: "100%"
            }}
          >
            <Button
              sx={{
                ...styles.linkButton,
                backgroundColor:
                  copiedId === params.id
                    ? Colors.Secondary_800
                    : "rgba(164, 176, 189, 0.25)",
                color: copiedId === params.id ? "white" : "black",
                "&:hover": {
                  backgroundColor:
                    copiedId === params.id ? Colors.Secondary_800 : Colors.Primary_600,
                  color: "white",
                },
                transition: "background-color 0.6s ease, color 0.6s ease",
              }}
              onClick={handleCopy}
            >
              <span
                className="material-symbols-outlined"
                style={{ fontSize: "12px", marginRight: "2px" }}
              >
                content_copy
              </span>
              {copiedId === params.id ? "Copied" : "Copy link"}
            </Button>
            <Button
              sx={{
                ...styles.linkButton,
                backgroundColor: "rgba(164, 176, 189, 0.25)",
                color: "black",
                "&:hover": {
                  backgroundColor:
                  Colors.Primary_600,
                  color: "white",
                },
                transition: "background-color 0.6s ease, color 0.6s ease",
              }}
              onClick={handleShare}
            >
              <span
                className="material-symbols-outlined"
                style={{ fontSize: "12px", marginRight: "2px" }}
              >
                share
              </span>
              {sendingEmail === true ? "Sending..." : "Send by email"}
            </Button>
          </Box>
        );
      },
    },
  ];

  return (
    <Box sx={mainBox}>
      {loading ? <LoadingSpinner/> : <></>}
      <DynamicEmailModal
        open = {shareLinkModal}
        handleClose = {() => setShareLinkModal(false)}
        handleSendByEmails = {(e) => RequestShareReferralLinkData(e)}
      />
      <NotificationModal
        open={showNotification}
        onClose={handleCloseNotification}
        iconType={modalProps.iconType}
        title={modalProps.title}
        message={modalProps.message}
        onAcceptLabel={modalProps.onAcceptLabel}
        onRefuseLabel={modalProps.onRefuseLabel}
        onAccept={modalProps.onAccept}
        onRefuse={modalProps.onRefuse}
      />
      <Paper elevation={2} sx={styles.mainPaper}>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={openSnackbar}
          autoHideDuration={2000}
          onClose={handleSnackbarClose}
          message="The link was successfully copied to your clipboard"
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
          }}
        >
          <Typography sx={TextVariables.Body_3_Bold}>Referral Links</Typography>
          <Typography sx={TextVariables.Body_4}>
            Share your links to invite clients to your trees and earn
            commissions! If you need a new link for your tree, you may request
            one from your broker
          </Typography>
        </Box>
        <Box style={{ height: 712, width: "100%" }}>
          <TableComponent
            handleDoubleRowClick={handleDoubleRowClick}
            rows={rows}
            columns={columns}
          />
        </Box>
      </Paper>
    </Box>
  );
}

export default TreesIndexPage;
