import * as TextVariables from "../../assets/Variables/TextVariables.js";
import Colors from "../../assets/Variables/ColorsVariables.js";
import {Spaces} from "../../assets/Variables/SpaceVariables.js";
import { GetApp } from "@mui/icons-material";

export const mainContainer = {
  display: "flex",
  width: "100%",
  flexDirection: "row",
  justifyContent: "center",
  height: "100%",
  padding: Spaces.Size_5,
};

export const logoContainer = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  gap: Spaces.Size_8,
  width: "596px"
};

export const formContainer = {
  display: "flex",
  flexDirection: "column",
  gap: "42px",
  paddingBottom: "50px",
};

export const mainPaperForm = {
  display: "flex",
  flexDirection: "row",
  width: "1165px",
  padding: Spaces.Size_8,
  borderRadius: Spaces.Size_5,
  background:
    "linear-gradient(114deg, rgba(228, 228, 228, 0.14)7.85%, rgba(251, 251, 251, 0.06)90.19%)",
    gap: Spaces.Size_5,
    boxShadow: "23px 13px 27px 0px rgba(60, 58, 75, 0.10), 25px 29px 26px -18px rgba(31, 26, 64, 0.02)"
};

export const paperForm = {
  display: "flex",
  flex: "1 0 0",
  flexDirection: "column",
  gap: Spaces.Size_9,
  padding: `${Spaces.Size_13} ${Spaces.Size_11}`,
  borderRadius: Spaces.Size_5
};

export const paperFormForgot = {
  display: "flex",
  flex: "1 0 0",
  flexDirection: "column",
  gap: Spaces.Size_5,
  padding: `${Spaces.Size_13} ${Spaces.Size_11}`,
  borderRadius: Spaces.Size_5
};

export const loginTitleBox = {
  display: "flex",
  flexDirection: "column",
  gap: Spaces.Size_6,
};

export const loginInputsBox = {
  display: "flex",
  flexDirection: "column",
  gap: Spaces.Size_4,
};

export const rememberMeBox = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  alignSelf: "stretch",
  gap: Spaces.Size_2,
};

export const checkBox = {
  width: Spaces.Size_5,
  height: Spaces.Size_5,
  border: "1px solid black",
};

export const loginButtonContainer = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center"
};

export const emptyFieldLabel = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: Spaces.Size_1,
  fontFamily: TextVariables.Caption_1,
  color: Colors.Error_700,
};

export const informationLogosBox = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  gap: Spaces.Size_1,
  width: "80px"
};

export const rememberForgotPasswordBox = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%"
};

export const link = {
  color: Colors.Secondary_900,
  textDecoration: "underline",
  cursor: "pointer",
  minWidth: "auto",
  textTransform: "none",
  background: "none",
  fontFamily: TextVariables.Caption_1,
  verticalAlign: "0",
  "&:hover": {
    background: "none",
    textDecoration: "underline",
  },
};

export const linkGoBack = {
  color: Colors.Secondary_900,
  textDecoration: "underline",
  cursor: "pointer",
  minWidth: "auto",
  textTransform: "none",
  background: "none",
  fontFamily: TextVariables.Body_4,
  verticalAlign: "0",
  "&:hover": {
    background: "none",
    textDecoration: "underline",
  },
  marginTop:"10px"
};

export const externalLink = {
  color: Colors.Secondary_900,
  textDecoration: "underline",
  cursor: "pointer",
  padding: 0,
  margin: 0,
  minWidth: "auto",
  textTransform: "none",
  background: "none",
  fontFamily: TextVariables.Caption_1,
  verticalAlign: "0",
  "&:hover": {
    background: "none",
    textDecoration: "underline",
  },
};


export const signUpBox = {
  display: "none",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  gap: Spaces.Size_1
};

export const disclaimerBox = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: Spaces.Size_4
};

export const disclaimerTitleBox = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: Spaces.Size_2
};

export const externalLinksBox = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: Spaces.Size_2
};

export const separatorPoint = {
  fontFamily: TextVariables.Caption_2_Bold,
  lineHeight: "1.4",
  color: Colors.Secondary_400,
  display: "flex",
  alignItems: "center",
};

export const disclouserExternalLinksBox = {
  display: "flex",
  flexDirection: "column",
  gap: Spaces.Size_4,
  width: "1165px",
};

export const formBox = {
  display: "flex",
  flexDirection: "column",
  gap: Spaces.Size_9,
};

export const resetSubtitle = {
  fontFamily: TextVariables.Body_4,
  lineHeight: "1.4",
  color: Colors.Secondary_400,
  display: "flex",
  alignItems: "center",
};
export const loginSubtitleBox = {
  display: "flex",
  flexDirection: "column",
};
export const goBackLink = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  width: "100%"
};
export const recoveryTitle = {
  fontFamily: TextVariables.Body_2_Bold,
  lineHeight: "1",
}
export const oneTimeCodeStyle = {
  textAlign: "center", 
  fontFamily: TextVariables.Body_2_Bold,
  height:"15px",
}
export const labelTitlesStyles = {
  fontFamily: TextVariables.Body_4,
  height:"15px",
  color: Colors.Secondary_700,
}