import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import {
  Typography,
  Paper,
  Box,
  Checkbox,
  Button,
  IconButton,
  Avatar,
} from "@mui/material";
import * as styles from "../Login/Styles.js";
import { Spaces } from "../../assets/Variables/SpaceVariables.js";
import * as TextVariables from "../../assets/Variables/TextVariables.js";
import TitledInput from "../../components/Inputs/TitledInput.js";
import IbPortalLogo from "../../assets/Icons/Group 41.svg";
import ContinueButton from "../../components/Buttons/ContinueButton.js";
import LoadingSpinner from "../../components/Loaders/SpinLoader.js";
import GetIpRequest from "../../Endpoints/GetIpRequest";
import LoginRequest from "../../Endpoints/LoginRequest";
import { useTranslation } from "react-i18next";
import { mainBox } from "../BackofficeRequest/Styles.js";
import { DeleteAllDataForLogin } from "../../Tools/LocalStorage";
import NotificationModal from "../../components/Notifications/NotificationModal.js";
import MaintenanceModal from "../../components/Notifications/MaintenanceModal.js";

function LoginView(props) {
  const [t] = useTranslation("global");
  const [loading, setLoading] = useState(false);
  const [isMaintenance, setIsMaintenance] = useState(false);
  const [modalSettings, setModalSettings] = useState({
    isOpen: false,
    modalTitle: "",
    modalStatus: "",
    iconType: "",
  });
  const [expanded, setExpanded] = useState(true);
  const [ip, setIp] = useState("");
  const [errors, setErrors] = useState({
    usu: false,
    pass: false,
    remember: false,
  });
  const [formValues, setFormValues] = useState({
    usu: "",
    pass: "",
    remember: false,
  });

  const navigate = useNavigate();
  const cookies = new Cookies();

  //-----------------------------------------------------------------------
  // Credentials and token cookies deleted
  //-----------------------------------------------------------------------
  cookies.remove("user", { path: "/" });
  cookies.remove("name", { path: "/" });
  cookies.remove("type", { path: "/" });
  cookies.remove("token", { path: "/" });
  DeleteAllDataForLogin();

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const handleChange = (event) => {
    let { name, value } = event.target;
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let valid = true;
    let newErrors = {};

    for (const [key, value] of Object.entries(formValues)) {
      if (key !== "remember") {
        if (value === "" || value === false) {
          newErrors[key] = true;
          valid = false;
        } else {
          newErrors[key] = false;
        }
      }
    }

    setErrors(newErrors);

    if (valid) {
      handleLoginRequest();
    } else {
      setModalSettings({
        isOpen: true,
        modalTitle: "Please, fill the missing data",
        iconType: "warning",
        onAcceptLabel: "Continue",
      });
    }
  };

  const handleCloseModal = () => {
    setModalSettings((prevObject) => ({
      ...prevObject,
      isOpen: false,
    }));
  };

  const handleRembemberCheck = (event) => {
    setFormValues({
      ...formValues,
      remember: event.target.checked,
    });
  };

  const getIp = () => {
    setLoading(true); //Starts loading spinning
    GetIpRequest() //Calls the method getIpClient from user service (axios request)
      .then((response) => {
        //Waits for a response from the axios request
        setLoading(false); //Stops loading spinning
        return response;
      })
      .then((response) => {
        if (response !== null) {
          setIp(response.data); //If response is correct, sets ip into the hook ip.
        } else {
          console.log("not ip");
          getIp();
        }
      })
      .catch((error) => {
        setLoading(false); //Stops loading spinning
        console.log(error);
        getIp();
      });
  };

  const handleLoginRequest = async (e) => {
    setLoading(true);
    try {
      const response = await LoginRequest({
        email: formValues.usu,
        password: formValues.pass,
        ip: ip,
        remember: formValues.remember,
      });
      if (response.data != null) {
        var userResponse = response.data;
        cookies.set("token", userResponse.data.token, { path: "/" });
        cookies.set("user", userResponse.data.email, { path: "/" });
        cookies.set("name", userResponse.data.name, { path: "/" });
        cookies.set("type", userResponse.data.role, { path: "/" });
        cookies.set("id", userResponse.data.id, { path: "/" });

        navigate("/dashboard");
      } else {
      }
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 400) {
        setModalSettings({
          isOpen: true,
          modalTitle: error.response.data.message,
          iconType: "error",
          onAcceptLabel: "Continue",
        });
      } else {
        setModalSettings({
          isOpen: true,
          modalTitle: "Bad request, please check your account information",
          iconType: "error",
          onAcceptLabel: "Continue",
        });
      }
      setLoading(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  //USe Effect hook triggered once per render, it calls a function to request the user ip
  useEffect(() => {
    getIp();
  }, []);

  return (
    <Box sx={styles.mainContainer}>
      {isMaintenance && (<MaintenanceModal open={isMaintenance} onClose={() => setIsMaintenance(false)} />)}
      {loading ? <LoadingSpinner /> : <></>}
      <NotificationModal
        open={modalSettings.isOpen}
        onClose={handleCloseModal}
        iconType={modalSettings.iconType}
        title={modalSettings.modalTitle}
        onAcceptLabel={modalSettings.onAcceptLabel}
      ></NotificationModal>
      <Box sx={mainBox}>
        <Box sx={styles.formContainer}>
          <Paper elevation={1} sx={styles.mainPaperForm}>
            <Box sx={styles.logoContainer}>
              <Typography
                sx={{ fontFamily: TextVariables.H3, lineHeight: "100%" }}
              >
                We've got you covered! Solutions for every need you have
              </Typography>
              <Box>
                <Box sx={styles.informationLogosBox}>
                  <Box>
                    <img alt="ib logo" src={IbPortalLogo} />
                  </Box>
                  <Typography sx={{ fontFamily: TextVariables.Body_4 }}>
                    Enter your IB portal
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Paper elevation={1} sx={styles.paperForm}>
              <Box sx={styles.loginTitleBox}>
                <Typography
                  sx={{
                    fontFamily: TextVariables.Body_2_Bold,
                    lineHeight: "1",
                  }}
                >
                  Login to your IB portal
                </Typography>
              </Box>
              <form noValidate style={styles.formBox}>
                <Box sx={styles.loginInputsBox}>
                  <TitledInput
                    name="usu"
                    value={formValues.usu}
                    title="Email"
                    placeholder="Enter your email"
                    handleChange={handleChange}
                    error={errors.usu}
                    helperText={
                      errors.usu ? (
                        <Typography sx={styles.emptyFieldLabel}>
                          {" "}
                          <span
                            className="material-symbols-outlined"
                            style={{ fontSize: Spaces.Size_3, color: "red" }}
                          >
                            error
                          </span>
                          Please, please enter your user
                        </Typography>
                      ) : (
                        ""
                      )
                    }
                  ></TitledInput>
                  <TitledInput
                    name="pass"
                    title="Password"
                    value={formValues.pass}
                    type="password"
                    placeholder="Enter your password"
                    handleChange={handleChange}
                    error={errors.pass}
                    helperText={
                      errors.pass ? (
                        <Typography sx={styles.emptyFieldLabel}>
                          {" "}
                          <span
                            className="material-symbols-outlined"
                            style={{ fontSize: Spaces.Size_3, color: "red" }}
                          >
                            error
                          </span>
                          Please, enter your password
                        </Typography>
                      ) : (
                        ""
                      )
                    }
                  ></TitledInput>
                </Box>
                <Box sx={styles.rememberForgotPasswordBox}>
                  <Box sx={styles.rememberMeBox}>
                    <Checkbox
                      name="remember"
                      checked={formValues.remember}
                      onChange={handleRembemberCheck}
                      color="primary" // Cambia el color del checkbox si lo deseas
                      sx={{ padding: "0px" }}
                    />
                    <Box>
                      <Typography sx={{ fontFamily: TextVariables.Caption_1 }}>
                        Remember me
                      </Typography>
                    </Box>
                  </Box>
                  <Button component="a" href="/forgot" sx={styles.link}>
                    Forgot your password?
                  </Button>
                </Box>
                <Box sx={styles.loginButtonContainer}>
                  <ContinueButton
                  HandleOnClick={handleSubmit}
                  >
                    Continue
                  </ContinueButton>
                </Box>
              </form>

              <Box sx={styles.signUpBox}>
                <Typography sx={{ fontFamily: TextVariables.Body_4 }}>
                  Don't have an IB account?
                </Typography>
                <Button component="a" href="/login" sx={styles.link}>
                  Sign up
                </Button>
              </Box>
            </Paper>
          </Paper>
          <Box sx={styles.disclaimerBox}>
            <Box sx={styles.disclaimerTitleBox}>
              <Typography sx={{ fontFamily: TextVariables.Caption_1 }}>
                Disclaimer
              </Typography>
              <IconButton
                onClick={handleToggle}
                sx={{
                  display: "flex",
                }}
              >
                <span
                  className="material-symbols-outlined"
                  style={{ fontSize: Spaces.Size_4, color: "red" }}
                >
                  expand_more
                </span>
              </IconButton>
              <Avatar 
                src={"https://cabinet.tvmarkets.com/img/ssl.45c2cf8b.svg"}
                alt="SSL Secure"
                variant="square"
                sx={{width: "1rem", height:"1.11119rem"}}
              />
              <Typography sx={{ fontFamily: TextVariables.Caption_1 }}>
                SECURE
              </Typography>
              <Typography sx={{ fontFamily: TextVariables.Caption_2 }}>
                SSL ENCRYPTION
              </Typography>
            </Box>
            {expanded && (
              <Box sx={styles.disclouserExternalLinksBox}>
                <Typography
                  sx={{ fontFamily: TextVariables.Caption_1, lineHeight: 1.4 }}
                >
                  The services and products offered by Tradeview Ltd. are not
                  being offered within the United States (US) and are not being
                  offered to US Persons, as defined under US law. The
                  information on this website is not directed to residents of
                  any country where FX and/or CFDs trading is restricted or
                  prohibited by local laws or regulations. CFDs are complex
                  instruments and come with a high risk of losing money rapidly
                  due to leverage. Between 74% - 89% of retail investors’
                  accounts lose money when trading CFDs. You should consider
                  whether you understand how CFDs work and whether you can
                  afford to take the high risk of losing your money.
                </Typography>
                <Box sx={styles.externalLinksBox}>
                  <Button 
                    component="a" 
                    href="https://documents-tvmarkets.s3.us-west-1.amazonaws.com/07_Terms+and+conditions.pdf" 
                    target="_blank"
                    sx={styles.externalLink}
                  >
                    Terms and Conditions
                  </Button>
                  <Typography sx={styles.separatorPoint}>&#8226;</Typography>
                  <Button 
                    component="a" 
                    href="https://documents-tvmarkets.s3.us-west-1.amazonaws.com/04_+Privacy+Policy.pdf" 
                    target="_blank"
                    sx={styles.externalLink}
                  >
                    Privacy Policy
                  </Button>
                  <Typography sx={styles.separatorPoint}>&#8226;</Typography>
                  <Button 
                    component="a" 
                    href="https://documents-tvmarkets.s3.us-west-1.amazonaws.com/08_AML+Policy.pdf" 
                    target="_blank"
                    sx={styles.externalLink}
                  >
                    AML Policy
                  </Button>
                  <Typography sx={styles.separatorPoint}>&#8226;</Typography>
                  <Button 
                    component="a" 
                    href="https://documents-tvmarkets.s3.us-west-1.amazonaws.com/09_Withdrawal+Policy.pdf" 
                    target="_blank"
                    sx={styles.externalLink}
                  >
                    Withdrawal Policy
                  </Button>
                  <Typography sx={styles.separatorPoint}>&#8226;</Typography>
                  <Button 
                    component="a" 
                    href="https://documents-tvmarkets.s3.us-west-1.amazonaws.com/10_Refund+Policy.pdf" 
                    target="_blank"
                    sx={styles.externalLink}
                  >
                    Refund Policy
                  </Button>
                  <Typography sx={styles.separatorPoint}>&#8226;</Typography>
                  <Button 
                    component="a" 
                    href="https://documents-tvmarkets.s3.us-west-1.amazonaws.com/03_+Risk+Disclosure.pdf" 
                    target="_blank"
                    sx={styles.externalLink}
                  >
                    Risk Disclosure
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default LoginView;
