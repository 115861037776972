import AxiosInstance from "./Axios";

const RecoverAccountRequest = async(params) => {
  try {    
    const res = await AxiosInstance.post('/recovery', params, {
      headers: {
        'x-auth-token-crm': `b45a80da-3c48-4dc3-b696-a62ec78ffde3`
        }
    });

    return res;
  } 
  catch (error) {
    console.error('error in request:', error);
    throw error; 
  }     
};

export default RecoverAccountRequest;