import React, { useEffect, useState } from "react";
import { Modal, Box, TextField, Button, Chip, Typography, Stack } from "@mui/material";
import * as TextVariables from "../../assets/Variables/TextVariables.js";
import AddIcon from "@mui/icons-material/Add";
import Colors from "../../assets/Variables/ColorsVariables.js";

const DynamicEmailModal = (props) => {
  const [email, setEmail] = useState("");
  const [emails, setEmails] = useState([]);

  const handleAddEmail = () => {
    if (email && !emails.includes(email)) {
      setEmails([...emails, email]);
      setEmail("");
    }
  };
  const handleClose = () => {
    setEmails([]);
    setEmail("");
    props.handleClose();
  };
  const handleDeleteEmail = (emailToDelete) => {
    setEmails(emails.filter((e) => e !== emailToDelete));
  };
  const handleSend = () => {
    props.handleSendByEmails(emails);
  };

  useEffect(() => {
    if(props.open === false){
      handleClose();
    }
  }, [props.open]);

  return (
    <Modal open={props.open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography sx={TextVariables.Body_3_Bold}>Share link to multiple emails</Typography>
        <br/>
        <Typography sx={{ fontFamily: TextVariables.Body_4 }}>
          Target email
        </Typography>
        <Stack direction="row" spacing={1} alignItems={"center"}>
          <TextField
            variant="outlined"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{
              "& .MuiInputBase-input": {
                fontFamily: TextVariables.Link_4,
              },
            }}
          />
          <Button 
            variant="contained" 
            onClick={handleAddEmail}
            sx={{
              minWidth:"70px",
              height:"40px",
              backgroundColor: "rgba(59, 61, 63, 0.25)",
              color:"black",
              fontFamily: TextVariables.Body_4,
              lineHeight: 1,
              textTransform: "none",
              "&:hover": {
                backgroundColor:Colors.Primary_600,
                color: "white",
              },
            }}  
          >
            <AddIcon sx={{fontSize:'18px'}}/>Add
          </Button>
        </Stack>
        <Stack direction="row" spacing={1} sx={{ mt: 2, flexWrap: "wrap" }}>
          {emails.map((email) => (
            <Chip 
              key={email} 
              label={email} 
              onDelete={() => handleDeleteEmail(email)}
              sx={{ justifyContent: "flex-start", minWidth: 100, width: "auto" }}
            />
          ))}
        </Stack>
        <Stack direction="row" spacing={1} sx={{ mt: 3, display:"flex", justifyContent:"center" }}>
          <Button 
            variant="contained" 
            onClick={handleSend}
            sx={{
              width:"160px",
              textTransform:'none',
              fontFamily: TextVariables.Link_4,
              background: Colors.Primary_600,
              "&:hover":{
                background: Colors.Primary_800,
              }
            }}
            >
            Send
          </Button>
          <Button 
            variant="contained"
            onClick={handleClose}
            sx={{
              width:"160px",
              textTransform:'none',
              fontFamily: TextVariables.Link_4,
              background: Colors.Secondary_400,
              "&:hover":{
                background: Colors.Secondary_600,
              }
            }}
          >
            Cerrar
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default DynamicEmailModal;