import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { Typography, Paper, Box, Button, IconButton, Avatar, TextField, InputAdornment} from "@mui/material";
import * as styles from "./Styles.js";
import { Spaces } from "../../assets/Variables/SpaceVariables.js";
import * as TextVariables from "../../assets/Variables/TextVariables.js";
import TitledInput from "../../components/Inputs/TitledInput.js";
import IbPortalLogo from "../../assets/Icons/Group 41.svg";
import ContinueButton from "../../components/Buttons/ContinueButton.js";
import LoadingSpinner from "../../components/Loaders/SpinLoader.js";
import GetIpRequest from "../../Endpoints/GetIpRequest.js";
import { useTranslation } from "react-i18next";
import { mainBox } from "../BackofficeRequest/Styles.js";
import { DeleteAllDataForLogin } from "../../Tools/LocalStorage.js";
import NotificationModal from "../../components/Notifications/NotificationModal.js";
import RecoverAccountRequest from "../../Endpoints/RecoverAccountRequest.js";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import OneTimeAuthRequest from "../../Endpoints/OneTimeAuthRequest.js";

function ForgotPassPage(props) {
  const [t] = useTranslation("global");
  const inputRefs = Array(6).fill(null).map(() => React.createRef());
  const [loading, setLoading] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const [ip, setIp] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [code, setCode] = useState(["", "", "", "", "", ""]);
  const [recoveryForm, setRecoveryForm] = useState(false);
  const [userEmailError, setUserEmailError] = useState("");
  const [passErrors, setPassErrors] = useState({ 
    newPassword: "", 
    confirmPassword: "" 
  });
  const [modalProps, setModalProps] = useState({
    iconType: "",
    title: "",
    message: "",
    onAcceptLabel: undefined,
    onRefuseLabel: undefined,
    onAccept: undefined,
    onRefuse: undefined,
  });
  const [passValues, setPassValues] = useState({
    newPassword: "",
    confirmPassword: "",
    showPassword: { new: false, confirm: false },
  });

  const navigate = useNavigate();
  const cookies = new Cookies();

  //-----------------------------------------------------------------------
  // Credentials and token cookies deleted
  //-----------------------------------------------------------------------
  cookies.remove("user", { path: "/" });
  cookies.remove("name", { path: "/" });
  cookies.remove("type", { path: "/" });
  cookies.remove("token", { path: "/" });
  DeleteAllDataForLogin();
  //-----------------------------------------------------------------------
  // General functions
  //-----------------------------------------------------------------------
  const handleToggle = () => {
    setExpanded(!expanded);
  };
  //-----------------------------------------------------------------------
  // Email recovery value
  //-----------------------------------------------------------------------
  const handleChangeUserEmail = (event) => {
    setUserEmail(event.target.value)
  };
  const handleUserEmailSubmit = (event) => {
    event.preventDefault();

    if(!recoveryForm){
      if(userEmail === null || userEmail === ""){
        setUserEmailError(true);
      }
      else{
        handleRecoverRequest();
      }
    }
    else{
      let valid = true;
      if(passErrors.newPassword === "" && passErrors.confirmPassword === ""){
        code.forEach(element => {
          if (element === "") {
            valid = false;
          }
        });
        if(valid){
          handleUpdatePasswordRequest();
        }      
      }
    }
  };
  const handleRecoverRequest = async (e) => {
    setLoading(true);
    try {
      const response = await RecoverAccountRequest({
        email: userEmail,
        ip: ip,
      });
      if (response.status === 200) {
        setLoading(false);
        setRecoveryForm(true);
      } 
      else {
        setModalProps({
          iconType: "error",
          title: "Detected error",
          message: response.data.data.message,
          onAcceptLabel: "Try again"
        });
        setShowNotification(true);
      }
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 400) {
        setModalProps({
          iconType: "error",
          title: "Detected error",
          message: error.response.data.message,
          onAcceptLabel: "Continue"
        });
        setShowNotification(true);
      } else {
        setModalProps({
          iconType: "error",
          title: "Bad request, please check your account information",
          message: "",
          onAcceptLabel: "Continue"
        });
        setShowNotification(true);
      }
    }

    setLoading(false);
  };
  //-----------------------------------------------------------------------
  // One-time code && change password
  //-----------------------------------------------------------------------
  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace" && !code[index] && index > 0) {
      inputRefs[index - 1].current.focus();
    }
  };
  const handleChangeOneTimeCode = (index, event) => {
    const value = event.target.value.replace(/\D/g, ""); // Asegurar solo números
    if (value.length > 1) return;
    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);

    if (value && index < 5) {
      inputRefs[index + 1].current.focus();
    }
  };
  const validatePassword = (password) => {
    const lengthValid = password.length >= 8;
    const upperValid = /[A-Z]/.test(password);
    const numberValid = /\d/.test(password);
    const specialCharValid = /[^a-zA-Z0-9]/.test(password);
    
    if (!lengthValid) return "Password must be at least 8 characters.";
    if (!upperValid) return "Password must contain at least one uppercase letter.";
    if (!numberValid) return "Password must contain at least one number.";
    if (!specialCharValid) return "Password must contain at least one special character.";
    return "";
  };
  const handlePasswordChange = (prop) => (event) => {
    const { value } = event.target;
    setPassValues({ ...passValues, [prop]: value });
    
    if (prop === "newPassword") {
      setPassErrors({ ...passErrors, newPassword: validatePassword(value) });
    }
    if (prop === "confirmPassword") {
      setPassErrors({ ...passErrors, confirmPassword: value !== passValues.newPassword ? "Passwords do not match." : "" });
    }
  };
  const handleClickShowPassword = (field) => {
    setPassValues({
      ...passValues,
      showPassword: { ...passValues.showPassword, [field]: !passValues.showPassword[field] },
    });
  };
  const handleUpdatePasswordRequest = async (e) => {
    setLoading(true);
    try {
      let oneTimeAuthCode = "";
      code.forEach(element => {
        oneTimeAuthCode += element;
      });

      const response = await OneTimeAuthRequest({
        email: userEmail,
        ip: ip,
        code: oneTimeAuthCode,
        newPassword: passValues.newPassword
      });
      if (response.status === 200) {
        setModalProps({
          iconType: "success",
          title: "Your password has been updated, now you can access your account again",
          message: response.data.data.message,
          onAcceptLabel: "Go to login",
          onAccept: () => handleClickToLoginPage(),
        });
        setShowNotification(true);
        setRecoveryForm(false);
      } 
      else {
        setModalProps({
          iconType: "error",
          title: "Error detected",
          message: response.data.data.message,
          onAcceptLabel: "Try again",
        });
        setShowNotification(true);
      }
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 400) {
        setModalProps({
          iconType: "error",
          title: "Error detected",
          message: error.response.data.message,
          onAcceptLabel: "Try again"
        });
        setShowNotification(true);
      } else {
        setModalProps({
          iconType: "error",
          title: "Bad request, please check your account information",
          message: "",
          onAcceptLabel: "Continue"
        });
        setShowNotification(true);
      }
    }

    setLoading(false);
  };

  const handleClickToLoginPage = () => {
    navigate("/login");
  }

  const handleCloseNotification = () => {
    setShowNotification(false);
  };

  const getIp = () => {
    setLoading(true); //Starts loading spinning
    GetIpRequest() //Calls the method getIpClient from user service (axios request)
      .then((response) => {
        //Waits for a response from the axios request
        setLoading(false); //Stops loading spinning
        return response;
      })
      .then((response) => {
        if (response !== null) {
          setIp(response.data); //If response is correct, sets ip into the hook ip.
        } else {
          console.log("not ip");
          getIp();
        }
      })
      .catch((error) => {
        setLoading(false); //Stops loading spinning
        console.log(error);
        getIp();
      });
  };

  

  useEffect(() => {
    getIp();
  }, []);

  return (
    <Box sx={styles.mainContainer}>
      {loading ? <LoadingSpinner /> : <></>}
      <NotificationModal
        open={showNotification}
        onClose={handleCloseNotification}
        iconType={modalProps.iconType}
        title={modalProps.title}
        message={modalProps.message}
        onAcceptLabel={modalProps.onAcceptLabel}
        onRefuseLabel={modalProps.onRefuseLabel}
        onAccept={modalProps.onAccept}
        onRefuse={modalProps.onRefuse}
      />
      <Box sx={mainBox}>
        <Box sx={styles.formContainer}>
          <Paper elevation={1} sx={styles.mainPaperForm}>
            <Box sx={styles.logoContainer}>
              <Typography
                sx={{ fontFamily: TextVariables.H3, lineHeight: "100%" }}
              >
                We've got you covered! Solutions for every need you have
              </Typography>
              <Box>
                <Box sx={styles.informationLogosBox}>
                  <Box>
                    <img alt="ib logo" src={IbPortalLogo} />
                  </Box>
                  <Typography sx={{ fontFamily: TextVariables.Body_4 }}>
                    Enter your IB portal
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Paper elevation={1} sx={styles.paperFormForgot}>
              <Box sx={styles.loginTitleBox}>
                <Typography sx={styles.recoveryTitle}>
                { recoveryForm ? "Set your new password" : "Reset your password" }
                </Typography>               
              </Box>
              <Box sx={styles.loginSubtitleBox}>
                <Typography sx={styles.resetSubtitle}>
                { recoveryForm ? 
                "We sent you an activation code to your registered email. Review your inbox and enter it below. After confirming the activation code, enter your new password" : 
                "Enter your registered email below and we’ll send you the instructions to reset your password." }
                </Typography>
              </Box>
              <form noValidate style={styles.formBox}>
                <Box sx={styles.loginInputsBox}>
                  { recoveryForm ?
                    <>
                      <Typography variant="p" sx={styles.labelTitlesStyles}>Verification code</Typography>
                      <Box display="flex" justifyContent="center" gap={1}>
                        {code.map((num, index) => (
                          <TextField
                            key={index}
                            inputRef={inputRefs[index]}
                            value={num}
                            onChange={(e) => handleChangeOneTimeCode(index, e)}
                            onKeyDown={(e) => handleKeyDown(index, e)}
                            variant="outlined"
                            inputProps={{ maxLength: 1, style: styles.oneTimeCodeStyle, pattern: "[0-9]*" }}
                            sx={styles.oneTimeCodeStyle}
                          />
                        ))}
                      </Box>
                      <Typography variant="p" sx={{...styles.labelTitlesStyles, marginTop:'40px', height:"0px"}}>New password</Typography>
                      <TextField
                        fullWidth
                        type={passValues.showPassword.new ? "text" : "password"}
                        variant="outlined"
                        margin="normal"
                        value={passValues.newPassword}
                        onChange={handlePasswordChange("newPassword")}
                        error={!!passErrors.newPassword}
                        helperText={passErrors.newPassword}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={() => handleClickShowPassword("new")}> 
                                {passValues.showPassword.new ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                      <Typography variant="p" sx={{...styles.labelTitlesStyles, marginTop:'0px', height:"0px"}}>Confirm password</Typography>
                      <TextField
                        fullWidth
                        type={passValues.showPassword.confirm ? "text" : "password"}
                        variant="outlined"
                        margin="normal"
                        value={passValues.confirmPassword}
                        onChange={handlePasswordChange("confirmPassword")}
                        error={!!passErrors.confirmPassword}
                        helperText={passErrors.confirmPassword}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={() => handleClickShowPassword("confirm")}> 
                                {passValues.showPassword.confirm ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    </>
                    :
                    <TitledInput
                      name="usu"
                      value={userEmail}
                      title="Email"
                      placeholder="Enter your email"
                      handleChange={handleChangeUserEmail}
                      error={userEmailError}
                      helperText={
                        userEmailError ? (
                          <Typography sx={styles.emptyFieldLabel}>
                            {" "}
                            <span
                              className="material-symbols-outlined"
                              style={{ fontSize: Spaces.Size_3, color: "red" }}
                            >
                              error
                            </span>
                            Please, please enter your user
                          </Typography>
                        ) : (
                          ""
                        )
                      }
                    />
                  }
                </Box>
                <Box sx={styles.loginButtonContainer}>
                  <ContinueButton HandleOnClick={handleUserEmailSubmit}>
                    Continue
                  </ContinueButton>
                </Box>
              </form>
              <Box sx={styles.goBackLink}>
                <Button component="a" href="/login" sx={styles.linkGoBack}>
                  Go back to log in
                </Button>
              </Box>
            </Paper>
          </Paper>
          <Box sx={styles.disclaimerBox}>
            <Box sx={styles.disclaimerTitleBox}>
              <Typography sx={{ fontFamily: TextVariables.Caption_1 }}>
                Disclaimer
              </Typography>
              <IconButton
                onClick={handleToggle}
                sx={{
                  display: "flex",
                }}
              >
                <span
                  className="material-symbols-outlined"
                  style={{ fontSize: Spaces.Size_4, color: "red" }}
                >
                  expand_more
                </span>
              </IconButton>
              <Avatar 
                src={"https://cabinet.tvmarkets.com/img/ssl.45c2cf8b.svg"}
                alt="SSL Secure"
                variant="square"
                sx={{width: "1rem", height:"1.11119rem"}}
              />
              <Typography sx={{ fontFamily: TextVariables.Caption_1 }}>
                SECURE
              </Typography>
              <Typography sx={{ fontFamily: TextVariables.Caption_2 }}>
                SSL ENCRYPTION
              </Typography>
            </Box>
            {expanded && (
              <Box sx={styles.disclouserExternalLinksBox}>
                <Typography
                  sx={{ fontFamily: TextVariables.Caption_1, lineHeight: 1.4 }}
                >
                  The services and products offered by Tradeview Ltd. are not
                  being offered within the United States (US) and are not being
                  offered to US Persons, as defined under US law. The
                  information on this website is not directed to residents of
                  any country where FX and/or CFDs trading is restricted or
                  prohibited by local laws or regulations. CFDs are complex
                  instruments and come with a high risk of losing money rapidly
                  due to leverage. Between 74% - 89% of retail investors’
                  accounts lose money when trading CFDs. You should consider
                  whether you understand how CFDs work and whether you can
                  afford to take the high risk of losing your money.
                </Typography>
                <Box sx={styles.externalLinksBox}>
                  <Button 
                    component="a" 
                    href="https://documents-tvmarkets.s3.us-west-1.amazonaws.com/07_Terms+and+conditions.pdf" 
                    target="_blank"
                    sx={styles.externalLink}
                  >
                    Terms and Conditions
                  </Button>
                  <Typography sx={styles.separatorPoint}>&#8226;</Typography>
                  <Button 
                    component="a" 
                    href="https://documents-tvmarkets.s3.us-west-1.amazonaws.com/04_+Privacy+Policy.pdf" 
                    target="_blank"
                    sx={styles.externalLink}
                  >
                    Privacy Policy
                  </Button>
                  <Typography sx={styles.separatorPoint}>&#8226;</Typography>
                  <Button 
                    component="a" 
                    href="https://documents-tvmarkets.s3.us-west-1.amazonaws.com/08_AML+Policy.pdf" 
                    target="_blank"
                    sx={styles.externalLink}
                  >
                    AML Policy
                  </Button>
                  <Typography sx={styles.separatorPoint}>&#8226;</Typography>
                  <Button 
                    component="a" 
                    href="https://documents-tvmarkets.s3.us-west-1.amazonaws.com/09_Withdrawal+Policy.pdf" 
                    target="_blank"
                    sx={styles.externalLink}
                  >
                    Withdrawal Policy
                  </Button>
                  <Typography sx={styles.separatorPoint}>&#8226;</Typography>
                  <Button 
                    component="a" 
                    href="https://documents-tvmarkets.s3.us-west-1.amazonaws.com/10_Refund+Policy.pdf" 
                    target="_blank"
                    sx={styles.externalLink}
                  >
                    Refund Policy
                  </Button>
                  <Typography sx={styles.separatorPoint}>&#8226;</Typography>
                  <Button 
                    component="a" 
                    href="https://documents-tvmarkets.s3.us-west-1.amazonaws.com/03_+Risk+Disclosure.pdf" 
                    target="_blank"
                    sx={styles.externalLink}
                  >
                    Risk Disclosure
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default ForgotPassPage;
